import React from 'react'
import { connect } from 'react-redux';
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { isEmptyObject, getAllUrlParam } from '../../utils/common'
import { ENV_ALIPAY, ENV_WEAPP } from '../../utils/compat'
import { Dialog } from 'antd-mobile-v5'

import styles from './flash-sale.module.scss'

import flashSaleBg from '../../assets/images/other/flash-sale-bg.jpg'
import flashSaleBtn from '../../assets/images/other/flash-sale-btn.png'

const mapStateToProps = store => ({
    userInfo: store.userInfo,
    appType: store.appType
});

class FlashSale extends React.Component {
    state={
        flashSaleArray: [],
        flashSale: {},
    }

    componentWillMount() {
        // /pages/webviews/manage?router=/other/flash-sale/1/0
        console.log(this.props)
        const { ruleId, payOk } = this.props.match.params;
        this.ruleId = ruleId;
        this.payOk = payOk;
        const params = getAllUrlParam(this.props.location.search.slice(1));
        this.getSeckillRuleMainInfo();
        if(payOk !== '0' && payOk !== null && payOk !== undefined){
            Dialog.show({
                content: (
                    <div style={{textAlign: 'center'}}>
                        <p style={{fontWeight: 'bold', fontSize: '20px', color: '#D39C59', marginBottom: 10}}>{payOk}</p>
                        <p>请自行截图保存后到店核销！</p>
                    </div>
                ),
                closeOnAction: true,
                actions: [
                    {
                        key: 'ok',
                        text: '我知道了',
                    }
                ],  
            })
        }
    }
    componentWillReceiveProps () {
        console.log('componentWillReceiveProps')
    }
    componentWillUnmount () {
        console.log('componentWillUnmount')
    }
    componentDidShow () {
        console.log('componentDidShow')
    }
    componentDidHide () { }

    async getSeckillRuleMainInfo(){
        await Fetch.get(api.getSeckillRuleMainInfo + `?SeckillRuleId=${this.ruleId ?? 1}`).then(res=>{
            this.setState({
                flashSale: res?.data ?? {}
            })
        })
    }

    getOrderModel(){
        const { flashSale } = this.state;
        return {
            SeckillRuleId: this.ruleId,
            SeckillPrice: flashSale?.SeckillPrice,
        };
    }

    onSubmit() {
        switch (this.props.appType) {
            case ENV_WEAPP:
                window.wx.miniProgram.navigateTo({
                    url: `/pages/order/confirm/flash_sale?data=${encodeURIComponent(JSON.stringify(this.getOrderModel()))}`
                });
                break;
            case ENV_ALIPAY:
                window.my.navigateTo({
                    url: `/pages/order/confirm/flash_sale?data=${encodeURIComponent(JSON.stringify(this.getOrderModel()))}`
                });
                break;
            default:
                window.wx.miniProgram.navigateTo({
                    url: `/pages/order/confirm/flash_sale?data=${encodeURIComponent(JSON.stringify(this.getOrderModel()))}`
                });
        }
    }


    render() {
        const { flashSaleArray, flashSale } = this.state;

        return (
            <div className={styles['flash-sale-pages']} style={{background: `#ffffff url(${flashSaleBg}) no-repeat top center/100%`}}>
                <div className='flash-sale-store'>{flashSale?.StoreName}</div>
                <div className='flash-sale-content'>
                    <div className='content-item'>
                        <div className='content-item-top'>
                            <div className='item-top-left'>
                                限时秒杀
                            </div>
                            {/* <div className='item-top-right'>
                                仅剩： 
                            </div> */}
                        </div>
                        <div className='content-item-bottom'>
                            <img className='item-pic' src={flashSale?.ProductImage}/>
                            <div className='item-bottom-right'>
                                <div className='title'>{flashSale?.ProductName}</div>
                                <div className='cost-price'>
                                    <span>￥</span>
                                    <span>{flashSale?.SeckillOrginPrice}</span>
                                </div>
                                <div className='price-spike'>
                                    <p>秒杀价</p>
                                    <p>
                                        <span className='yuan'>￥</span>
                                        <span className='price'>{flashSale?.SeckillPrice}</span>
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                        <div className='content-item-remark'>
                            秒杀规则：{flashSale?.SeckillRemark}
                        </div>
                        
                        

                        {flashSaleArray.length >= 2 && (<button className='flash-sale-item-button' onClick={()=>this.onSubmit()}>
                            立即抢购
                        </button>)}
                    </div>
                </div>
                <div className='flash-sale-button-box'>
                    {flashSaleArray.length < 2 && <button className='flash-sale-button' style={{background: `url(${flashSaleBtn}) no-repeat top center/100%`}} onClick={()=>this.onSubmit()}></button>}
                </div>
            </div>
        )
    }
}

FlashSale = connect(mapStateToProps)(FlashSale) || FlashSale;
export default FlashSale